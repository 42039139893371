import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Trans, useTranslation } from 'react-i18next';
import * as R from 'ramda';

import * as Alert from '@common/services/alert';
import { Row } from '@common/components/form';
import MarkupEditor from '@common/components/form/inputs/markup';
import { Button } from '@common/components/button';
import Poll, { TPollForm } from '@common/components/form/inputs/poll';
import { useFormValues } from '@common/hooks';
import { useComponentWillMount } from '@common/hooks/use-component-will-mount';
import editMessage from '../../actions/edit-message';
import { validatePoll } from '../validators/message';
import { Message } from '@modules/social/types/objects';
import { PostMessagePayload } from '@modules/social/actions/post-message.types';

type FormData = {
  text?: string;
  poll?: TPollForm;
  showPoll: boolean;
  hasAttachments: boolean;
};

type EditMessageProps = {
  message: Message;
  hasAttachments: boolean;
  onClose: () => void;
};

const EditMessage = ({
  message, hasAttachments, submitting, valid, form,
  handleSubmit, onClose, change, initialize,
}: InjectedFormProps<FormData, EditMessageProps, boolean> & EditMessageProps) => {
  const { t } = useTranslation();
  const formValues = useFormValues(form);

  // !!Warning: ugly workaround
  // Should be called before mounting, otherwise the
  // MarkupEditor component will not set the correct value
  useComponentWillMount(() => {
    const canEditPoll = message.publish_at && !message.processed_at;
    initialize({
      text: message.text,
      showPoll: !!(canEditPoll && !!(message.poll && message.poll.question)),
      hasAttachments,
      poll: (canEditPoll && !!(message.poll && message.poll.question) && {
        ...message.poll,
        expires_at: message.poll!.expires_at || false,
        options: ((message.poll && message.poll.options) || []).map(({ id, text }) => ({ id, value: text })),
      }) || undefined,
    });
  });

  const onSubmit = handleSubmit(async (values, dispatch) => {
    const data: Partial<PostMessagePayload> = {
      text: values.text,
      poll_question: null,
      poll_options: null,
    };

    if (values.showPoll && values.poll) {
      data.poll_question = values.poll.question;
      data.poll_options = R.pipe(R.pluck('value'), R.reject(R.isEmpty))(values.poll.options);
      data.poll_is_multiple_choice = values.poll.is_multiple_choice;
      data.poll_show_votes = values.poll.show_votes;
      if (values.poll.expires_at) {
        data.poll_expires_at = values.poll.expires_at;
      }
    }

    try {
      await dispatch(editMessage(message.id, data, !!message.publish_at));
      onClose();
    } catch (response: any) {
      Alert.forStatus(response.status_code, {
        warning: t('social:form_edit_message_warning_posting_message'),
        error: t('social:form_edit_message_error_posting_message'),
      });
    }
  });

  return (
    <form className="EditMessageForm Form" onSubmit={onSubmit}>
      <Row>
        <MarkupEditor
          dispatchUpdatesOnChange
          target={message.created_in}
          name="text"
        />
      </Row>
      {formValues?.showPoll && (
        <Row>
          <Poll
            name="poll"
            closePoll={() => change('showPoll', false)}
          />
        </Row>
      )}

      <Button
        type="primary"
        buttonType="submit"
        isLoading={submitting}
        disabled={!valid}
      >
        <Trans i18nKey="social:form_edit_message_save_changes" />
      </Button>
      <Button onClick={onClose}>
        <Trans i18nKey="social:form_edit_message_cancel" />
      </Button>
    </form>
  );
};

const EditMessageForm = reduxForm<FormData, EditMessageProps, boolean>({
  initialValues: {
    text: '',
    showPoll: false,
    hasAttachments: false,
    poll: {
      question: '',
      options: [],
      is_multiple_choice: false,
      show_votes: true,
      expires_at: false,
    },
  },
  validate: (values) => {
    const validPoll = validatePoll(values.poll);
    return {
      poll: values.showPoll && !validPoll,
      text: !values.showPoll && !values.hasAttachments && !values.text,
    };
  },
})(EditMessage);

export default EditMessageForm;

import React, {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import createTempId from '@common/utils/create-temp-id';
import { timeStrToMinutes } from '../../../../utils/time';

import { TextareaInput } from '@common/components/form/inputs/textarea';
import { Checkbox } from '@common/components/form/inputs/checkbox';
import { TimePickerInput } from '@common/components/form/inputs/time';
import { DateInput } from '@common/components/form/inputs/date';
import { Group, Row } from '@common/components/form';
import { Button } from '@common/components/button';
import PureList from '@common/components/list';
import Icon from '@common/components/icon';
import Tooltip from '@common/components/tooltip';
import Option, { TPollOption } from './option';
import Label from '../../label';

import { dateAndTimeToStr, getMinTimeValue, stringToTimePickerValue } from '@common/utils/date';

const MAX_LENGTH_QUESTION = 250;

export type TPollForm = {
  question: string;
  options: TPollOption[];
  is_multiple_choice?: boolean;
  show_votes?: boolean;
  expires_at?: false | string;
};

export type PollInputOwnProps = {
  value: TPollForm;
  closePoll?: () => void;
  onChange: (newValue: PollInputOwnProps['value']) => void;
};

export const PollInput = ({
  value, closePoll, onChange,
}: PollInputOwnProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const now = moment();

  const [expiresAtTime, setExpiresAtTime] = useState<string | undefined>(
    value.expires_at === false ?
      undefined :
      stringToTimePickerValue(value.expires_at),
  );
  const [expiresAtDate, setExpiresAtDate] = useState<Moment | null>(
    value.expires_at === false ?
      null :
      moment(value.expires_at)
  );

  const minValue = useMemo(() => getMinTimeValue(expiresAtDate), [expiresAtDate]);

  useEffect(() => {
    if (ref.current) {
      const options = ref.current.childNodes;
      const input = options[options.length - 1].childNodes[1] as HTMLElement;
      if (input) {
        input.focus();
      }
    }
  }, [value.options.length, ref]);

  useEffect(() => {
    if (value.expires_at !== false) {
      if (expiresAtDate && expiresAtTime) {
        const expiresAt = dateAndTimeToStr(expiresAtTime, expiresAtDate);
        // Reset time if the selected time is not available for the selected date
        if (minValue && timeStrToMinutes(expiresAtTime) < timeStrToMinutes(minValue)) {
          setExpiresAtTime(undefined);
        }
        if (value.expires_at !== expiresAt) {
          onChange({
            ...value,
            expires_at: expiresAt,
          });
        }
      }
    } else if (expiresAtDate || expiresAtTime) {
      setExpiresAtDate(null);
      setExpiresAtTime(undefined);
    }
  }, [expiresAtDate, expiresAtTime, value, minValue, onChange, setExpiresAtDate, setExpiresAtTime]);

  const handleRemoveOption = useCallback((idToRemove) => {
    onChange({
      ...value,
      options: [...value.options.filter(({ id }) => id !== idToRemove)],
    });
  }, [value, onChange]);

  const handleAddNewOption = useCallback(() => {
    onChange({
      ...value,
      options: [...value.options, {
        id: createTempId(),
        value: '',
      }],
    });
  }, [value, onChange]);

  const handleChangeOption = useCallback((indexToChange, text) => {
    onChange({
      ...value,
      options: [...value.options.map((item, index) => {
        return indexToChange === index ? { ...value.options[index], value: text } : item;
      })],
    });
  }, [value, onChange]);

  return (
    <div className="Form__Poll">
      <div className="Form__Poll__Content">
        <div className="Form__Poll__Close">
          <Icon type="close" onClick={closePoll} />
        </div>
        <div className="Form__Poll__Question">
          <Label
            text={t('common:form_input_poll_question')}
            maxLength={MAX_LENGTH_QUESTION}
            input={{ value: value.question }}
          />
          <TextareaInput
            value={value.question}
            placeholder={t('common:form_input_poll_question_placeholder')}
            maxLength={MAX_LENGTH_QUESTION}
            onChange={(question) => onChange({ ...value, question })}
          />
        </div>

        <PureList
          containerRef={ref}
          containerClassName="Form__Poll__Options"
          items={value.options}
          renderRow={Option}
          rowProps={{
            onAddNewOption: handleAddNewOption,
            onChange: handleChangeOption,
            onRemove: handleRemoveOption,
          }}
        />

        <Button
          className="Form__Poll__Add"
          onClick={handleAddNewOption}
        >
          <Icon type="add" />
          <span>
            {t('common:form_input_poll_add_option')}
          </span>
        </Button>
      </div>

      <div className="Form__Poll__Footer">
        <Row>
          <Checkbox
            label={t('common:form_input_poll_is_multiple_choice')}
            value={!!value.is_multiple_choice}
            onChange={(is_multiple_choice) => onChange({
              ...value,
              is_multiple_choice,
            })}
          />
        </Row>
        <Row>
          <Checkbox
            label={(
              <>
                {t('common:form_input_poll_show_votes')}
                <Tooltip title={t('common:form_input_poll_show_votes_tooltip')}>
                  <Icon type="info" />
                </Tooltip>
              </>
            )}
            value={!!value.show_votes}
            onChange={(show_votes) => onChange({
              ...value,
              show_votes,
            })}
          />
        </Row>
        <Row>
          <Checkbox
            label={t('common:form_input_poll_expires_at')}
            value={value.expires_at !== false}
            onChange={(expires_at) => onChange({
              ...value,
              expires_at: expires_at ? '' : false,
            })}
          />
        </Row>
        {value.expires_at !== false && (
          <Row>
            <Group>
              <DateInput
                minDate={now}
                value={expiresAtDate}
                onChange={(date) => setExpiresAtDate(date)}
                placeholder={t('common:form_input_poll_expires_at_date')}
              />
            </Group>
            <Group>
              <TimePickerInput
                minValue={minValue}
                value={expiresAtTime}
                onChange={(time) => setExpiresAtTime(time)}
                placeholder={t('common:form_input_poll_expires_at_time')}
              />
            </Group>
          </Row>
        )}
      </div>
    </div>
  );
};

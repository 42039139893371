import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Icon from '@common/components/icon';
import Modal from '@common/components/modal';
import AsyncList from '@common/components/list/async';
import ImageItem from '@common/components/image-item/user';

import { Api } from '@common/services/api';
import { combineClassNames } from '@common/utils/combineClassNames';
import { PollOption } from '@modules/social/types/objects';
import { User } from '@common/types/objects';

type PollOptionComponentProps = {
  item: PollOption;
  pollId: string;
  percentage: number;
  selected: boolean;
  disabled?: boolean;
  showVoters?: boolean;
  showVotes?: boolean;
  onSelect?: () => void;
};

const PollOptionComponent = ({
  item, pollId, percentage, selected, disabled, showVotes, showVoters, onSelect,
}: PollOptionComponentProps) => {
  const { t } = useTranslation();
  const className = combineClassNames('PollOption', {
    'PollOption--selected': selected,
    'PollOption--active': !!onSelect && !disabled,
    'PollOption--disabled': disabled,
  });

  const handleSelect = useCallback(() => {
    if (!disabled && onSelect) onSelect();
  }, [disabled, onSelect]);

  return (
    <div className="PollOption__Wrapper">
      <div className={className}>
        <div
          className="PollOption__Checkbox"
          onClick={handleSelect}
        >
          {selected && (
            <Icon
              className="PollOption__CheckboxIcon"
              type="check"
            />
          )}
        </div>
        <div
          className="PollOption__Container"
          onClick={handleSelect}
        >
          {(percentage !== 0 && (
            <div
              className="PollOption__Bar"
              style={{
                width: `${Math.max(percentage || 0, 0.1)}%`
              }}
            >
              <div
                className="PollOption__Label"
                style={{
                  width: `calc(${100 * (100 / Math.max(percentage || 0, 0.1))}% - 32px)`
                }}
              >
                <span>
                  {item.text}
                </span>
                {(showVotes || showVoters) && (
                  <div className="PollOption__Percentage">
                    {Math.round(percentage) || 0} %
                  </div>
                )}
              </div>
            </div>
          )) || (
            <div className="PollOption__Label">
              {item.text}
            </div>
          )}
        </div>
      </div>

      {(showVoters || showVotes) && (
        <div className="PollOption__Stats">
          <Trans i18nKey="social:poll_vote_count" values={{ count: item.vote_count }} />
          {showVoters && item.vote_count > 0 && (
            <Modal
              title={t('social:poll_voters')}
              content={(
                <AsyncList
                  data={{
                    onFetch: async (offset = 0, filter: { pollId?: string, id?: string } = {}, limit = 25) => {
                      return Api.get<{ data: User[], meta: { pagination: {} } }>(
                        `/v1/polls/${filter.pollId}/options/${filter.id}/users?offset=${offset}&limit=${limit}`
                      );
                    },
                    useCursor: undefined,
                    filter: {
                      id: item.id,
                      pollId,
                    },
                  }}
                  renderRow={ImageItem}
                  rowProps={{
                    className: 'PollOption__StatsUser',
                  }}
                  placeholder={t('social:poll_placeholder_nobody_voted')}
                />
              )}
            >
              <a>{t('social:poll_see_voters')}</a>
            </Modal>
            )}
        </div>
      )}
    </div>
  );
};

export default PollOptionComponent;

import { TPollForm } from '@common/components/form/inputs/poll';
import * as fileUtil from '@common/utils/file';

export const validatePoll = (poll?: TPollForm) => {
  if (!poll) return false;
  const validDeadline = poll.expires_at === false || poll.expires_at !== '';
  const validOptions = poll.options.filter(({ value }) => !!value);
  return poll.question !== '' && validOptions.length > 1 && validDeadline;
};

export const validateAttachments = (attachments: any[]) => {
  return !fileUtil.isProcessingAttachments(attachments);
};

import { useRef } from 'react';

// Warning! This hook should be used with care!
// In most cases useEffect will be suficient.
// Only use when you really need to execute something
// before a component is mounted.
export const useComponentWillMount = (cb: () => void) => {
  const willMount = useRef(true);
  if (willMount.current) cb();
  willMount.current = false;
};
